import Button from '@amzn/awsui-components-react/polaris/button';
import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const FileUpload = ({ state, setFileState, acceptedFileTypes }) => {
  const hiddenFileInput = React.createRef();

  const setFile = file => {
    const { form } = state;
    setFileState({ ...state, form: { ...form, file } });
  };

  const fileName = get(state, 'form.file.name', '');

  const size = () => {
    const sizeInBytes = get(state, 'form.file.size', '');
    if (sizeInBytes !== '') return `${Math.floor(sizeInBytes / 1024)} KB`;
    return '';
  };

  const lastModified = () => {
    const absLastModified = get(state, 'form.file.lastModified', '');
    if (absLastModified !== '') return new Date(absLastModified).toLocaleString();
    return '';
  };

  const hintText = !isEmpty(acceptedFileTypes) ? `Supported file format: ${acceptedFileTypes}` : '';
  return (
    <SpaceBetween size="l">
      <div>
        <input
          ref={hiddenFileInput}
          id="chooseJSONInput"
          type="file"
          hidden
          multiple={false}
          accept={acceptedFileTypes}
          onChange={event => setFile(event.target.files[0])}
        />
        <Button
          id="chooseJSONButton"
          iconName="upload"
          formAction="none"
          onClick={() => {
            hiddenFileInput.current.value = null;
            hiddenFileInput.current.click();
          }}
        >
          Choose File
        </Button>
        <TextContent>
          <small>{hintText}</small>
        </TextContent>
      </div>
      {!isEmpty(fileName) && (
        <div>
          <SpaceBetween direction="horizontal" size="xs">
            {fileName !== '' ? (
              <Icon name="status-positive" size="small" variant="success" />
            ) : (
              <div />
            )}
            <SpaceBetween size="xxxs">
              <TextContent>
                <div>{fileName}</div>
                <div>
                  <small>{size()}</small>
                </div>
                <div>
                  <small>{lastModified()}</small>
                </div>
              </TextContent>
            </SpaceBetween>
          </SpaceBetween>
        </div>
      )}
    </SpaceBetween>
  );
};

FileUpload.propTypes = {
  state: PropTypes.object.isRequired,
  setFileState: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.string.isRequired,
};

export default FileUpload;
